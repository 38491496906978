import React, { Component } from 'react'
import { Navbar, Nav, Form, Image,Button, Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import Carousel from 'react-multi-carousel';
import ReactCardFlip from 'react-card-flip';
import Lottie from 'lottie-react-web';
import site from './animacoes/site.json'
import loja from './animacoes/loja.json'
import app from './animacoes/app.json'
import soft from './animacoes/soft.json'
import { Icon, InlineIcon } from '@iconify/react';
import facebookFill from '@iconify/icons-akar-icons/facebook-fill';
import instagramFill from '@iconify/icons-akar-icons/instagram-fill';
import linkedinFill from '@iconify/icons-akar-icons/linkedin-fill';
import behanceCircleFilled from '@iconify/icons-ant-design/behance-circle-filled';
import githubFill from '@iconify/icons-akar-icons/github-fill';
import sendPlaneFill from '@iconify/icons-ri/send-plane-fill';
import FaWhatsapp from '@iconify/icons-ri/whatsapp-line';
import menuico from '@iconify/icons-ri/menu-4-fill';
import { Link, animateScroll as scroll } from "react-scroll";
import { stack as Menu } from 'react-burger-menu'
import axios from "axios";
import { ToastContainer ,toast } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";
import 'react-multi-carousel/lib/styles.css';
import './style.css';


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1034 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1034 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
};



export default class home extends Component {
    constructor() {
        super();
            this.listener = null;
            this.state = {
                viraSites: false,
                viraApp: false,
                viraLoja: false,
                viraSoft: false,
                fundoNav: 'none',
                abremenu: false,
                nome:'',
                telefone:'',
                email:'',
                texto:'' ,
                interesse:''               
            };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.listener = document.addEventListener("scroll", e => {
            var scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 120) {
              if (this.state.fundoNav !== "#843a84") {
                this.setState({ fundoNav: "#843a84" });
              }
            } else {
              if (this.state.fundoNav !== "#843a8400") {
                this.setState({ fundoNav: "#843a8400" });
              }
            }
          });
        
    }

    linkWhats = ()=>{
        window.open('https://api.whatsapp.com/send?phone=5511977654721&text=Ol%C3%A1%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20desenvolvimento','_blank')
    }
    
    linkLogin = ()=>{
        window.open('http://user.wfdesenvolvimento.com.br/','_blank')
    }

    linkFace = ()=>{
        window.open('https://www.facebook.com/wfdesenvolvimento/','_blank')
    }
    linkInsta = ()=>{
        window.open('https://www.instagram.com/wfdesenvolvimento/','_blank')
    }
    linklink = ()=>{
        window.open('https://www.linkedin.com/company/wf-dev','_blank')
    }
    linkbe = ()=>{
        window.open('https://www.behance.net/didico','_blank')
    }
    linkgit = ()=>{
        window.open('https://github.com/didicobe','_blank')
    }
    linkEmail = ()=>{
        window.open('mailto:contato@wfdesenvolvimento.com.br?subject=Contato direto site WF Desenvolvimento','_blank')
    }
    ligaTelefone1 = ()=>{
        window.open('tel:+5511977654721','_self')
    }
    ligaTelefone2 = ()=>{
        window.open('tel:+5511984598655','_self')
    }

    handleClick(valor) {
        if(valor === 'viraSites'){
            this.setState(prevState => ({ viraSites: !prevState.viraSites }));
        }
        
        if(valor === 'viraApp'){
            this.setState(prevState => ({ viraApp: !prevState.viraApp }));
        }
        
        if(valor === 'viraLoja'){
            this.setState(prevState => ({ viraLoja: !prevState.viraLoja }));
        }
        
        if(valor === 'viraSoft'){
            this.setState(prevState => ({ viraSoft: !prevState.viraSoft }));
        }
        
    }

    toggleMenu () {
        this.setState(state => ({abremenu: !state.abremenu}))
    }
    closeMenu () {
        this.setState({abremenu: false})
    }
    handleStateChange (state) {
        this.setState({abremenu: state.abremenu})  
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async onSubmit(e) {
        
        const dados = { 
            nome: this.state.nome,
            telefone: this.state.telefone,
            email: this.state.email,
            texto: this.state.texto,
        }
        

        const headers = { 
            'Authorization': 'jW|SZmY52Exj6HJ'
        };


        await axios.post('https://wfdesenvolvimento.com.br/api/email', dados, {headers})
            .then(response => {
                this.setState({
                    nome:'',
                    telefone:'',
                    email:'',
                    texto:'',
                    
                })
                toast.success('😁 Orçamento enviado com sucesso!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });

            })
            .catch(error => {
                toast.error('🥺 Erro ao enviar, veja se preencheu todos os campos obrigatórios!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.error('aqui', error);
            });


    }

    async onSubmitinteresse(e) {
        
        const dados = { 
            email: this.state.mail
        }
        const headers = { 
            'Authorization': 'jW|SZmY52Exj6HJ'
        };


        await axios.post('http://wfdesenvolvimento.com.br/api/email', dados, {headers})
            .then(response => {
                this.setState({
                    nome:'',
                    telefone:'',
                    mail:'',
                    tiposerv:'',
                    tipodev:'',
                    ctt:'',
                    conhece:''
                })
                toast.success('😁 Entraremos em contato o mais rápido possível!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });

            })
            .catch(error => {
                toast.error('🥺 Erro ao enviar, veja se preencheu o seu e-mail!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.error('aqui', error);
            });


    }

    
    stylesMenu = {
        bmCrossButton: {
          height: '24px',
          width: '24px'
        },
        bmCross: {
          background: '#bdc3c7'
        },
        bmMenuWrap: {
          position: 'fixed',
          height: '100%'
        },
        bmMenu: {
          background: '#803a85',
          padding: '2.5em 1.5em 0',
          fontSize: '1.15em'
        },
        bmMorphShape: {
          fill: '#803a85',
        },
        bmItemList: {
          color: '#b8b7ad',
          padding: '1px',
          width:150
        },
        bmItem: {
          marginTop:'15px'
        },
        bmOverlay: {
          background: 'rgba(0, 0, 0, 0.3)'
        },
        
    }

    render() {
        return (
            <div id="home">
                <ToastContainer/>
                <Menu right customBurgerIcon={ false } styles={ this.stylesMenu } className="menu" width={ 200 } isOpen={this.state.abremenu} onStateChange={(state) => this.handleStateChange(state)}>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                    >Home</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="sobre"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                    >Sobre</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="servico"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    >Serviços</Link>
                    <Link
                    onClick={()=>{this.toggleMenu()}}
                    className="linkmenu"
                    activeClass=""
                    to="contato"
                    spy={true}
                    smooth={true}
                    offset={-75}
                    duration={500}
                    >Orçamento</Link>
                </Menu>
                
                
                <div className="superior">
                    {/* menu */}
                    <Navbar className="justify-content-between topo some" fixed="top" style={{backgroundColor:this.state.fundoNav,transition:'background-color 200ms linear'}}>
                        <Navbar.Brand href="#home" className="logo" onClick={()=>scroll.scrollToTop()}>
                            <Image src="./img/logo.png" fluid style={{width:120}}/>
                        </Navbar.Brand>
                        <Nav className="menus">
                            
                            <Link
                                className="linkmenu"
                                activeClass=""
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Home</Link>
                            <Link
                                className="linkmenu"
                                activeClass=""
                                to="sobre"
                                spy={true}
                                smooth={true}
                                offset={-140}
                                duration={500}
                                >Sobre</Link>
                            <Link
                                className="linkmenu"
                                activeClass=""
                                to="servico"
                                spy={true}
                                smooth={true}
                                offset={-75}
                                duration={500}
                                >Serviços</Link>
                            <Link
                                className="linkmenu"
                                activeClass=""
                                to="contato"
                                spy={true}
                                smooth={true}
                                offset={-75}
                                duration={500}
                                >Orçamento</Link>
                        </Nav>
                        <Form inline>
                            <Button className="btnLogin" onClick={()=>this.linkLogin()}>Login</Button>
                        </Form>
                    </Navbar>
                    {/* menu */}
                    {/* menu mobile */}
                    <div className="d-md-none d-lg-none d-xl-none menuMobile"  style={{backgroundColor:this.state.fundoNav,transition:'background-color 200ms linear'}}>
                        <Image src="./img/logo.png" fluid style={{width:120}} onClick={()=>scroll.scrollToTop()}/>
                        <div><Icon icon={menuico} onClick={()=>{this.toggleMenu()}}/></div>
                        
                    </div>
                    {/* menu mobile */}

                    {/* banner */}
                    <Container >
                        <Row>
                            <Col md={6}>
                                <div>
                                    <div>
                                        <h3 className="titulobanner">Dar vida a sua ideia é o<br/>nosso objetivo</h3>
                                        <div className="subtitulobanner">
                                            Estamos prontos para ajudar você e sua empresa a<br/>melhorar os resultados.
                                        </div>
                                        <div className="btnbanner">
                                            <Button className="btnLogin">
                                                <Link
                                                    activeClass=""
                                                    to="servico"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-75}
                                                    duration={500}
                                                    >Saiba mais</Link>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Image src="./img/BitmapbannerTopo.png" fluid style={{marginTop:50}} className="float-right animate__animated animate__slow animate__pulse animate__infinite"/>
                            </Col>
                        </Row>
                    </Container>
                    {/* banner */}
                </div>

                {/* sobre nos */}
                <Container style={{marginTop:50}} id="sobre">
                    <Row>
                        <Col md={6}>
                            <div>
                                <ScrollAnimation animateIn='bounceInLeft'>
                                    <div className="tituloSobre">Sobre Nós</div>
                                    <p className="textoSobre">A WF Desenvolvimento é uma startup que foi criada em meio a pandemia que assolou o mundo e visando a necessidade digital que cresce cada vez mais em todos os setores, queremos ajudar você a obter melhores resultados e otimizar o seu próprio negócio.<br/>Especializada em desenvolver sistemas, sites, e-commerce, app para Android e IOS e integrações multiplataformas, oferecemos o melhor custo x benefício à nossos clientes e qualidade de serviço dos nossos colaboradores com mais de 10 anos de experiência no mercado.
                                    <br/></p>
                                </ScrollAnimation>
                            </div>
                        </Col>
                        <Col md={6}>
                            <ScrollAnimation animateIn='bounceInRight'>
                                <Image src="./img/illustrationimgsobre.png" fluid style={{marginTop:50,width:400,paddingLeft:35}} className=""/>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
                {/* sobre nos */}

                {/* servicos */}
                <div className="servicobox" style={{marginTop:50,marginBottom:50}} id="servico">
                    <Container fluid >
                        <Row>
                            <Col>
                                <div className="tituloServico">Serviços</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{paddingRight:0}}>
                                <Carousel responsive={responsive} arrows={true} showDots={false}  slidesToSlide={1} swipeable containerClass="centro">
                                    <div>
                                        <ReactCardFlip isFlipped={this.state.viraSites} flipDirection="horizontal" >
                                            <div className="cardservico" onClick={()=>this.handleClick('viraSites')}>
                                                <div className="titulocard">Sites</div>
                                                <div style={{padding:25}}>
                                                    <Lottie
                                                        options={{
                                                        animationData: site
                                                        }}
                                                    />
                                                </div>
                                                <button  className="btnCard">Veja mais</button>
                                            </div>

                                            <div className="cardservico" onClick={()=>this.handleClick('viraSites')}>
                                                <div className="titulocard">Sua empresa precisa ter a sua cara</div>
                                                <div style={{padding:25}}>Desenvolvemos sites para divulgação do seu produto ou prestação de serviço e dessa forma possa te auxiliar a atingir seu público alvo. Ter um site bem estruturado passa mais credibilidade e facilita a comunicação entre você e seu cliente.</div>
                                            </div>
                                        </ReactCardFlip>
                                    </div>

                                    <div>
                                        <ReactCardFlip isFlipped={this.state.viraApp} flipDirection="horizontal" >
                                            <div className="cardservico"  onClick={()=>this.handleClick('viraApp')}>
                                                <div className="titulocard">Aplicativos Mobile</div>
                                                <div style={{padding:25}}>
                                                    <Lottie
                                                        options={{
                                                        animationData: app
                                                        }}
                                                    />
                                                </div>
                                                <button className="btnCard">Veja mais</button>
                                            </div>

                                            <div className="cardservico"  onClick={()=>this.handleClick('viraApp')}>
                                                <div className="titulocard">Você não vai ficar fora dessa né?</div>
                                                <div style={{padding:25}}>Tenha o seu aplicativo na google play ou na apple store, multiplique suas vendas, esteja mais proximo do seu cliente.</div>
                                            </div>
                                        </ReactCardFlip>
                                    </div>

                                    <div>
                                        <ReactCardFlip isFlipped={this.state.viraLoja} flipDirection="horizontal" >
                                            <div className="cardservico"  onClick={()=>this.handleClick('viraLoja')}>
                                                <div className="titulocard">Lojas virtuais</div>
                                                <div style={{padding:25}}>
                                                    <Lottie
                                                        options={{
                                                        animationData: loja
                                                        }}
                                                    />
                                                </div>
                                                <button className="btnCard">Veja mais</button>
                                            </div>

                                            <div className="cardservico"  onClick={()=>this.handleClick('viraLoja')}>
                                                <div className="titulocard">Venda muito mais com muito menos</div>
                                                <div style={{padding:25}}>Temos a solução completa para a criação de sua loja virtual de forma organizada e interligada a diversas formas de pagamento. Hoje em dia a venda de produtos e serviços de forma virtual é a maneira mais eficiente e barata de divulgação. Invista em uma loja virtual e alavanque suas vendas.</div>
                                            </div>
                                        </ReactCardFlip>
                                    </div>

                                    <div>
                                        <ReactCardFlip isFlipped={this.state.viraSoft} flipDirection="horizontal" >
                                            <div className="cardservico"  onClick={()=>this.handleClick('viraSoft')}>
                                               <div className="titulocard" style={{marginTop:0}}>Softwares e Integrações</div>
                                                <div style={{padding:25}}>
                                                    <Lottie
                                                        options={{
                                                        animationData: soft
                                                        }}
                                                    />
                                                </div>
                                                <button className="btnCard">Veja mais</button>
                                            </div>

                                            <div className="cardservico" onClick={()=>this.handleClick('viraSoft')}>
                                                <div className="titulocard">Aumente sua produtividade</div>
                                                <div style={{padding:25}}>Nossa empresa tem grande experiência no desenvolvimento de softwares proprietários, personalizado e integrações com diversas plataformas Amazon, Google, Oracle e etc. Integração e desenvolvimento em qualquer plataforma.</div>
                                            </div>
                                        </ReactCardFlip>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* servicos */}

                {/* orcamento */}
                <div className="orcamentobox" style={{marginTop:470,marginBottom:50}} id="contato">
                    <Container fluid >
                        <Row>
                            <Col>
                                <div className="tituloOrcamento">Orçamento</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <ScrollAnimation animateIn='bounceInLeft'>
                                    <div style={{textAlign:'center'}}>
                                        <Image src="./img/orcamento.png" fluid className="imgOrcamento"/>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={6}>
                                
                                <div>
                                    <ScrollAnimation animateIn='bounceInRight'>
                                        <div className="cardForm">
                                            <div className="tituloform">Diga o que precisa, nós resolvemos para você!</div>
                                            <input type="text" onChange={(e)=>this.onChange(e)} className="inputform" id="nome" name="nome" placeholder="Nome"/>
                                            <input type="text" onChange={(e)=>this.onChange(e)} className="inputform" id="telefone" name="telefone" placeholder="Telefone"/>
                                            <input type="text" onChange={(e)=>this.onChange(e)} className="inputform" id="email" name="email" placeholder="E-mail"/>
                                            <textarea onChange={(e)=>this.onChange(e)} className="textareaform" id="texto" rows="6" name="texto" placeholder="Informe aqui o que precisa"></textarea>
                                            <div className="btnFormenviar" onClick={()=>this.onSubmit()}>Enviar</div>                                            
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* orcamento */}

                {/* clientes */}
                <div style={{marginTop:550,marginBottom:50}}>
                   <Container fluid>
                       <Row>
                           <Col style={{zIndex:-99999}}>
                                <div className="tituloClientes">Alguns de nossos clientes que colaboraram conosco</div>
                                <div className="subClientes">Buscamos cada vez mais parceirias sólidas e duradouras com nossos clientes</div>
                           </Col>
                       </Row>
                       <Row className="justify-content-center">
                           <Col>
                                <Carousel responsive={responsive2} centerMode={true} infinite={true} autoPlay={true} transitionDuration={500} arrows={false}  containerClass="centroClientes">
                                <div style={{textAlign:'center'}}>
                                    <img alt="AllMarketing Digital" src="./img/cli/allmkt.png" className="imgcli"/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <img alt="Chapa drinks" src="./img/cli/chapadrinks.png" className="imgcli"/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <img alt="Clean Beauty" src="./img/cli/cleanbeauty.png" className="imgcli"/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <img alt="Eskenazi" src="./img/cli/eskenazi.png" className="imgcli"/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <img alt="Fontinhas" src="./img/cli/fontinhas.png" className="imgcli"/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <img alt="Loja gráfica Eskenazi" src="./img/cli/lojaeskenazi.png" className="imgcli"/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <img alt="Penha Refrigeração" src="./img/cli/penha.png" className="imgcli"/>
                                </div>
                           </Carousel>
                           </Col>
                       </Row>
                       <Row className="justify-content-center" style={{marginTop:50}}>
                           <Col md={3}>
                                <div className="cardDepoimento">
                                    <div style={{display:'flex',flexDirection:'row',alignContent:'center',alignItems:'center',paddingBottom:24,borderBottom:"1px solid #F6F4F3"}}>
                                        <div>
                                            <Image src="./img/cli/clientes/penha.jpg" style={{width:48, borderRadius:8,marginRight:15}}/>
                                        </div>
                                        <div>
                                            <div style={{fontSize: '16px',fontWeight: '700',lineHeight: '24px',letterSpacing: '0.20000000298023224px',color: '#1a1a1a',}}>Fernanda Piovezan</div>
                                            <div style={{fontSize: '14px',fontWeight: '500',lineHeight: '16px',letterSpacing: '0.5833333134651184px',color: '#595959',}}>Penha Refrigeração</div>
                                        </div>
                                    </div>  
                                    <div style={{fontSize: '16px',lineHeight: '26px',letterSpacing: '0.25px',color: '#595959',marginTop:15}}>
                                    Solicitei um serviço da WFdesenvolvimento para integração do sistema com a minha loja virtual e me surpreendi positivamente com o resultado. Profissionais atenciosos e que dão suporte sempre que necessário 😉
                                    </div>
                                </div>
                           </Col>
                           <Col md={3}>
                                <div className="cardDepoimento">
                                    <div style={{display:'flex',flexDirection:'row',alignContent:'center',alignItems:'center',paddingBottom:24,borderBottom:"1px solid #F6F4F3"}}>
                                        <div>
                                            <Image src="./img/cli/clientes/chapa.jpg" style={{width:48, borderRadius:8,marginRight:15}}/>
                                        </div>
                                        <div>
                                            <div style={{fontSize: '16px',fontWeight: '700',lineHeight: '24px',letterSpacing: '0.20000000298023224px',color: '#1a1a1a',}}>Lucas - CEO</div>
                                            <div style={{fontSize: '14px',fontWeight: '500',lineHeight: '16px',letterSpacing: '0.5833333134651184px',color: '#595959',}}>Chapa Drinks</div>
                                        </div>
                                    </div>  
                                    <div style={{fontSize: '16px',lineHeight: '26px',letterSpacing: '0.25px',color: '#595959',marginTop:15}}>
                                    Produtividade e Excelência em processos define a WF
Passei 3 experiências frustradas com profissionais de tecnologia no mercado por falta de compromisso e sinceridade com prazos na entrega dos projetos, na WF desde o atendimento até o andamento dos nossos projetos eles são sinceros, dizem como funciona e tem expertise para levar o projeto além dando uma consultoria excelente
                                    </div>
                                </div>
                           </Col>
                           <Col md={3}>
                                <div className="cardDepoimento">
                                    <div style={{display:'flex',flexDirection:'row',alignContent:'center',alignItems:'center',paddingBottom:24,borderBottom:"1px solid #F6F4F3"}}>
                                        <div>
                                            <Image src="./img/cli/clientes/eskenazi.jpg" style={{width:48, borderRadius:8,marginRight:15}}/>
                                        </div>
                                        <div>
                                            <div style={{fontSize: '16px',fontWeight: '700',lineHeight: '24px',letterSpacing: '0.20000000298023224px',color: '#1a1a1a',}}>Carina Santana</div>
                                            <div style={{fontSize: '14px',fontWeight: '500',lineHeight: '16px',letterSpacing: '0.5833333134651184px',color: '#595959',}}>Eskenazi</div>
                                        </div>
                                    </div>  
                                    <div style={{fontSize: '16px',lineHeight: '26px',letterSpacing: '0.25px',color: '#595959',marginTop:15}}>
                                        Pessoal da WF tem o melhor suporte do mercado e com certeza sabem o que fazem, entregam sempre no prazo e estão sempre atualizando nosso sistema, melhorando ainda mais nossa automatização, sem sombra de dúvidas são os melhores do mercado.
                                    </div>
                                </div>
                           </Col>
                       </Row>
                   </Container>
                </div>
                {/* clientes */}


                {/* footer */}
                <div className="inferior">
                    <Container fluid>
                        <Row>
                            <Col md={3}>
                                <div  style={{width:200}} className="logoFooter">
                                    <Image src="./img/logo.png" fluid/>
                                    <div className="social">
                                        <Icon icon={facebookFill} onClick={()=>this.linkFace()}/>
                                        <Icon icon={instagramFill} onClick={()=>this.linkInsta()} />
                                        <Icon icon={linkedinFill} onClick={()=>this.linklink()} />
                                        <Icon icon={behanceCircleFilled} onClick={()=>this.linkbe()} />
                                        <Icon icon={githubFill} onClick={()=>this.linkgit()} />
                                    </div>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div style={{display:'flex'}} className="endereco">
                                    <div className="linksUteis">
                                        <div style={{marginTop:0,fontWeight:400}}>Links úteis</div>
                                        <div>
                                            <Link
                                            style={{color:'inherit',textDecoration:'none'}}
                                            activeClass=""
                                            to="home"
                                            spy={true}
                                            smooth={true}
                                            offset={-140}
                                            duration={500}
                                            >Home</Link>
                                        </div>
                                        <div>
                                            <Link
                                            style={{color:'inherit',textDecoration:'none'}}
                                            activeClass=""
                                            to="sobre"
                                            spy={true}
                                            smooth={true}
                                            offset={-140}
                                            duration={500}
                                            >Sobre</Link>
                                        </div>
                                        <div>
                                            <Link
                                            style={{color:'inherit',textDecoration:'none'}}
                                            activeClass=""
                                            to="servico"
                                            spy={true}
                                            smooth={true}
                                            offset={-75}
                                            duration={500}
                                            >Serviços</Link>
                                        </div>
                                        <div>
                                            <Link
                                            style={{color:'inherit',textDecoration:'none'}}
                                            activeClass=""
                                            to="contato"
                                            spy={true}
                                            smooth={true}
                                            offset={-75}
                                            duration={500}
                                            >Orçamento</Link>
                                        </div>
                                    </div>
                                    <div className="linksUteis">
                                        <div style={{marginTop:0,fontWeight:400}}>Endereço</div>
                                        <div>
                                            WF Desenvolvimento<br/>
                                            Av. Henrique Gonçalves Baptista, 2245<br/>
                                            Jardim Belval - Barueri - SP - CEP: 06420-130
                                        </div>
                                        <div>
                                            <span onClick={()=>this.ligaTelefone1()}>+55 11 97765-4721</span>
                                            <br/>
                                            <span onClick={()=>this.ligaTelefone2()}>+55 11 98459-8655</span>
                                        </div>
                                        <div onClick={()=>{this.linkEmail()}}><span>contato@wfdesenvolvimento.com.br</span></div>
                                    </div>

                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="titulosend">Ficou interessado em nossos<br/> serviços?</div>
                                <input type="mail" className="inputEmail" id="interesse" name="interesse" onChange={(e)=>this.onChange(e)} placeholder="Coloque seu e-mail aqui"/>
                                <div className="btnSend"><Icon icon={sendPlaneFill}  onClick={()=>this.onSubmitinteresse()}/> enviar</div>                       
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{marginTop:50,textAlign:'center',fontWeight:400,fontSize:16,color:'#eeeeee',padding:35,borderTop:'1px solid'}}>
                                    Desenvolvido por  WF Desenvolvimento - 2021
                                </div>
                            </Col>
                        </Row>
                    </Container>                                     
                </div>
                {/* footer */}

                {/* whatsapp lateral */}
                <div className="pulse2"  onClick={()=>this.linkWhats()}>
                    <Icon icon={FaWhatsapp} className="whats"  style={{color:'#71bd34', fontSize:80,textShadow:'0 0 2px #fff'}} />
                </div>
                {/* whatsapp lateral */}
            </div>
        )
    }
}
